// "process.env.NODE_ENV" production or development
const BASE_URL = process.env.NODE_ENV === 'production' ?
  'https://www.vanjupe.com.mx/vis/mvc' : 'https://vis.vanjupe.dev/vis/mvc'

const FIREBASE_OPTIONS = process.env.NODE_ENV === 'production' ?
  {
    apiKey: "AIzaSyB-2N2zquOclWzx_iulIW4YUKrsO2FD6-s",
    authDomain: "envios-vjp.firebaseapp.com",
    projectId: "envios-vjp",
    storageBucket: "envios-vjp.appspot.com",
    messagingSenderId: "533378146836",
    appId: "1:533378146836:web:271f68e57da68bbc0f7d01"
  } :
  {
    apiKey: "AIzaSyC0jWt313M6HH4GXhC7XKV6UurvfFq33RA",
    authDomain: "envios-vjp-test.firebaseapp.com",
    projectId: "envios-vjp-test",
    storageBucket: "envios-vjp-test.appspot.com",
    messagingSenderId: "894564663054",
    appId: "1:894564663054:web:45f91967f99fb58e31e15f"
  }

export default {
  ACCESS_TOKEN_KEY: 'ACCESS_TOKEN',
  CURRENT_USER_KEY: 'CURRENT_USER',
  BASE_URL,
  FIREBASE_OPTIONS,
  API_KEY_GOOGLE_MAPS: 'AIzaSyDpOjfL1G7_BWOre_uSRa6m01M4N0j6yA8',
  COORDENADAS_VJP_ALMACEN: { lat: 19.6338493, lng: -99.207762 },
  COLORES_ESTATUS: {
    'Por enviar': '#424242',
    'Entrega programada': '#FFC107',
    'En ruta': '#2196F3',
    'Entrega cancelada': '#FF5252',
    'Entrega reprogramada': '#FF5722',
    'Entregada': '#4CAF50'
  },
  VISTA_OPERADOR_KEY: 'VISTA_OPERADOR'
}
