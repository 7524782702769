import crud from './CrudService';
import FechaService from './FechaService';

const getRutasDesdeAdministrador = (fecha = FechaService.getFechaString()) => {
  return new Promise(async (resolve, reject) => {
    try {
      let rutas = await crud.buscarPorCampo('rutas', fecha, 'fecha')

      if (rutas.length > 0) {
        let ruta = rutas[0]
        ruta.operadores = await crud.getCollection('rutas/' + ruta.id + '/operadores')
        resolve(ruta)
      } else {
        let rutaNueva = esFechaDisponibleParaAsignarRutas(fecha)
          ? await crud.agregar('rutas', { fecha })
          : { id: 0, fecha };
        rutaNueva.operadores = []
        resolve(rutaNueva)
      }
    } catch (e) {
      reject(e)
    }
  })
}

const getRutasDesdeOperador = (fecha = FechaService.getFechaString()) => {
  return new Promise(async (resolve, reject) => {
    try {
      let rutas = await crud.buscarPorCampo('rutas', fecha, 'fecha')

      if (rutas.length > 0) {
        let ruta = rutas[0]
        resolve(ruta)
      } else {
        resolve({})
      }
    } catch (e) {
      reject(e)
    }
  })
}

const getZonasOperador = (idRuta, idOperador) => {
  return new Promise(async (resolve, reject) => {
    try {
      let rutaOperador = await crud.getDoc('rutas/' + idRuta + '/operadores', idOperador)
      if (rutaOperador)
        resolve(rutaOperador)
      else
        resolve({ id: idOperador, zonas: [] })
    } catch (e) {
      reject(e)
    }
  })
}

const updateZonasOperador = (idRuta, zonasOperador) => {
  return crud.actualizar('rutas/' + idRuta + '/operadores', zonasOperador)
}

const getPedidosRuta = (ruta) => {
  if (ruta.zonas)
    return ruta.zonas.reduce((pedidos, zona) => pedidos.concat(zona.pedidos), [])

  return []
}

const getZonasConClave = () => {
  return [...Array(12).keys()].map(n => ({zona: 'Z' + (n + 1)}));
}

function esFechaDisponibleParaAsignarRutas(fecha) {
  return !FechaService.enRangoDentroDeFechaLimite(fecha, -1)
}

export default { getRutasDesdeAdministrador, getRutasDesdeOperador, getZonasOperador, updateZonasOperador,
  getPedidosRuta, getZonasConClave }
